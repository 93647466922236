<script lang="ts" setup>

</script>

<template>
<div class="container doc">
  <div class="row">
    <div class="col">
      <slot></slot>
    </div>
  </div>
</div>
</template>

<style lang="scss" >
section {
  flex-grow: 1;
}

$max-width: 1000px;

.doc.container {
  background: #fefefefe;
  flex-grow: 1;
  box-shadow: 1px 1px 9px 0px #dddddd;
  max-width: $max-width;
  margin-bottom: 1rem;
  padding: 3rem 2rem;
  font-size: 0.8rem;
  
  p {
    text-align: justify;
  }

  
  @media only screen and (min-width: 800px) {
    font-size: 1rem;
    padding: 4rem 4rem;
  }

  @media only screen and (min-width: $max-width) {
    margin-top: 2rem;
    margin-bottom: 6rem;
    padding: 6rem 7rem;
  }
}

</style>