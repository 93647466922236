<script setup lang="ts">
</script>

<template>
  <footer>
    <div class="container-fluid">
      <div class="footer-main">
        <div class="row">
          <div class="col">
            <div>
              <h6>Company</h6>
              <a href="mailto:contact@polareclip.se">Contact</a>
            </div>
          </div>
          
          <div class="col">
            <div>
              <h6>Products</h6>
              <router-link to="/payment" class="nav-link">Payment</router-link>
              <router-link to="/shipping-policy" class="nav-link">Shipping</router-link>
              <router-link to="/return-policy" class="nav-link">Return Policy</router-link>
            </div>
          </div>
          
          <div class="col">
            <div>
              <h6>Info</h6>
              <router-link to="/terms-of-service" class="nav-link">Terms of Service</router-link>
              <router-link to="/privacy-policy" class="nav-link">Privacy Policy</router-link>
              <router-link to="/cookie-policy" class="nav-link">Cookies</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="copyright">
          Copyright © {{ new Date().getFullYear() }} Polar Eclipse AB
          <a href="/"><img class="float-end" src="@/assets/polaricon.svg"/></a>
        </div>
        <div class="social-links" />
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  footer {
    background-color: #0e1e2b;
    padding-top: 1rem;

    > div {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .footer-main {
    padding: 1rem 0;
    text-align: left;

    .col > div {
      margin-left: auto;
      margin-right: auto;
      display: table;
    }

    h6 {
      font-weight: bold;
      color: #eeeeee;
    }

    a, router-link {
      text-decoration: none;
      display: block;
      padding: 0.5em 0;
      font-size: 0.8rem;
      color: #ababab;
      cursor: pointer;

      &:hover {
        color: #909090;
      }
    }
  }

  .footer-bottom {
    display: flex;
    padding: 1rem;

    border-top: 1px solid #1f3138;

    .social-links {
      margin-left: auto;
    }
  }

  .copyright {
    font-size: 0.7em;
    color: #dddddd;
    width: 100%;

    img {
      height: 1rem;
    }
  }

</style>