import { Player } from "./types"
import { pickRandom } from "./utils"

const PHRASES = [
  'please welcome',
  'give a warm welcome to',
  'put your hands together for',
  'we are proud to announce',
  'we are glad to have',
]

export const generateTeeOffMessage = (player: Player, teeOffIndex?: number) => {
  const intro = (teeOffIndex === 0) ? 'First out' : 'Next up on the tee'

  const sponsorMessage = generateSponsorMessage(player.sponsors)

  return `${intro}${sponsorMessage}, ${pickRandom(PHRASES)} ${player.name}.`
}

const generateSponsorMessage = (sponsors: string[]) => {
  if (sponsors.length === 0) return ''

  const _sponsors = sponsors.slice()

  const intro = ', sponsored by'
  
  if (_sponsors.length > 2) {
    const last = _sponsors.pop()
    return `${intro} ${_sponsors.join(', ')} and ${last}`
  }

  return `${intro} ${_sponsors.join(' and ')}`
}