import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from '@/Toaster'
import { MotionPlugin } from '@vueuse/motion'

const app = createApp(App)
  .use(router)
  .use(MotionPlugin)

app.config.errorHandler = (err, vm, info) => {
  console.error('[APP ERROR]', err, info)
  Toaster.showError('Oops something went wrong 😬' + JSON.stringify(err))
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in
}


app.mount('#app')