enum ACTION {
    PING = 'PING',
    GET_PROPERTIES = 'GET_PROPERTIES',
    SET_PROPERTIES = 'SET_PROPERTIES',
    GENERATE_FILES = 'GENERATE_FILES',
  }  

export class App<Properties extends Record<string, any>> {
  private iframe!: HTMLIFrameElement
  private listeners: Partial<Record<ACTION, ((payload: any) => void)[]>> = {}

  constructor (private url: string) {}

  async mountTo (element: HTMLElement) {
    this.iframe = document.createElement('iframe')
    this.iframe.src = this.url

    element.appendChild(this.iframe)
    console.log('[App]: Mounted')

    window.addEventListener('message', (e) => {
      if (!this.url.startsWith(e.origin)) return false
      if (!Object.values(ACTION).includes(e.data.action)) return console.error('unknown incoming action', e.data.action)
      this.handleResponse(e.data)
    })

    await new Promise((resolve) => this.iframe.onload = resolve)

    await this.ping()
    console.log('[App]: Connection OK')

  }

  private handleResponse (data: { action: ACTION, payload: any }) {
    this.listeners[data.action]?.forEach(listener => listener(data.payload))
    this.listeners[data.action] = []
  }

  private req <T>(action: ACTION, payload?: any): Promise<T> {
    return new Promise((resolve) => {
      this.iframe.contentWindow?.postMessage({ action, payload }, '*')
      this.listeners[action] = this.listeners[action] ?? []
      this.listeners[action]?.push((payload) => {
        resolve(payload)
      })
    })
  }

  async ping () { return this.req<string>(ACTION.PING) }
  async getProperties () { return this.req<Properties>(ACTION.GET_PROPERTIES) }
  async setProperties (properties: Partial<Properties>) { return this.req<Properties>(ACTION.SET_PROPERTIES, properties) }
  async generateFiles () { return this.req<{ name: string, data: any }[]>(ACTION.GENERATE_FILES) }
}