import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home/Home.vue'
import E404 from '@/views/pages/error/E404.vue'
import PrivacyPolicy from '@/views/pages/PrivacyPolicy.vue'
import CookiePolicy from '@/views/pages/CookiePolicy.vue'
import TermsOfService from '@/views/pages/TermsOfService.vue'
import ReturnPolicy from '@/views/pages/ReturnPolicy.vue'
import ShippingPolicy from '@/views/pages/ShippingPolicy.vue'
import Product from '@/views/Product/Product.vue'

import EventFinder from '@/apps/EventFinder/EventFinder.vue'
import EventFinderFAQ from '@/apps/EventFinder/FAQ.vue'

import TeeTimeAnnouncer from '@/apps/TeeTimeAnnouncer/TeeTimeAnnouncer.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About/About.vue')
  },
  { path: '/contact', redirect: '#contact' },

  // * Pages
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/cookie-policy', component: CookiePolicy },
  { path: '/terms-of-service', component: TermsOfService },
  { path: '/return-policy', component: ReturnPolicy },
  { path: '/shipping-policy', component: ShippingPolicy },
  
  // * Products
  { path: '/products/:product', component: Product },
  { path: '/products', redirect: '#products' },
  
  // * Services
  { path: '/services/event-finder', component: EventFinder },
  { path: '/services/event-finder/faq', component: EventFinderFAQ },
  { path: '/services/tee-time-announcer', component: TeeTimeAnnouncer },
  { path: '/services', redirect: '#services' },

  // * 404
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: E404,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      top: 0,
      left: 0,
      behavior: 'auto',
    }
  }
})

export default router
