const logger = {
  info: (...messages: unknown[]) => console.log('[Spotify SDK]:', ...messages),
  error: (...messages: unknown[]) => console.error('[Spotify SDK]:', ...messages),
}

const authEndpoint = 'https://accounts.spotify.com/authorize'
const clientId = 'b2f0f1be2e6d4926a30977518d461890'

interface SpotifyAccessToken {
  access_token: string
  expires_in: string
  token_type: string
}

export interface AccessToken {
  valid: boolean
  accessToken: string
  expiresIn: number
}

export interface Client {
  play: (spotifyUri: string) => void
  pause: () => void
}

const parseAccessTokenHash = (hash: string): SpotifyAccessToken => hash && Object.fromEntries(hash.split('#')[1].split('&').map(x => x.split('=')))

export const parseAccessToken = () => {
  const tokenObj = parseAccessTokenHash(location.hash)

  const valid = tokenObj.token_type === 'Bearer'

  location.hash = ''

  return {
    valid,
    accessToken: tokenObj.access_token,
    expiresIn: Number(tokenObj.expires_in),
  }
}

export const requestAccessToken = () => {
  const scopes = [
    'streaming'
  ]

  parent.window.location.href = `${authEndpoint}?client_id=${clientId}&redirect_uri=${location.href}&scope=${scopes.join('%20')}&response_type=token&show_dialog=true`

  logger.info('Refreshing access token')
}

export const initAPI = async (accessToken: string): Promise<Client> => new Promise ((resolve, _) => {
  logger.info('loading...')
  
    const script = document.createElement('script')
    script.src = 'https://sdk.scdn.co/spotify-player.js'
    document.body.append(script)
    
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.onSpotifyWebPlaybackSDKReady = async () => {
      logger.info('Ready!')
      logger.info('Creating Player')
  
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const player = new Spotify.Player({
        name: 'AGDA Tee Time Announcer',
        getOAuthToken: (callback: (accessToken: string) => void) => {
          // Run code to get a fresh access token
          // TODO: refresh token
          const accessToken = ''
  
          callback(accessToken)
        },
        volume: 0.5
      })
  
      await player.connect()
      .then((success: boolean) => {
        if (success) {
          logger.info(`The Web Playback SDK successfully connected to Spotify!`)
        } else {
          logger.error('Connection failed 👺')
        }
      })
      
      player.addListener('ready', ({ device_id }: { device_id: string}) => {
        logger.info('The Web Playback SDK is ready to play music!');
        logger.info('Device ID', device_id);
  
        resolve({
          play: async (spotifyUri: string) => {
            await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${device_id}`, {
              method: 'PUT',
              body: JSON.stringify({ uris: [spotifyUri] }),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
            })
          },
          pause: () => player.pause()
        })
      })
    }
  })
