export function getFormattedDate(date: string) {
  if (!date) return ''
  const dateArray = date.split('-')
  const dayNumber = dateArray[2]
  const monthString = [
    'januari',
    'februari',
    'mars',
    'april',
    'maj',
    'juni',
    'juli',
    'augusti',
    'september',
    'oktober',
    'november',
    'december',
  ][parseInt(dateArray[1]) - 1]

  return `${parseInt(dayNumber)} ${monthString}`
}

export function camelCaseToInitcap (text: string) {
  const formatted = text.replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5').split(' ').map(x => x[0].toUpperCase() + x.substring(1)).join(' ')
  
  return formatted
}