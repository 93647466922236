import { createToaster } from '@meforma/vue-toaster'

const toaster = createToaster()

const position = 'top-right'

const Toaster = {
  showInfo(message: string) {
    toaster.show(message, { type: 'info', position })
  },

  showWarning(message: string) {
    toaster.show(message, { type: 'warning', position })
  },

  showError(message: string) {
    toaster.show(message, { type: 'error', position })
  },
}

export default Toaster