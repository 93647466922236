import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { RowGroup } from '@/apps/EventFinder/DataTable.vue'
// @ts-expect-error unknown
import logo from '@/assets/logo.png'

export async function downloadPdf (element: HTMLElement) {
  const canvas = await html2canvas(element)
  const PADDING = 10
  
  const imgData = canvas.toDataURL('image/png')
  const pdf = new jsPDF({
    orientation: 'portrait',
  })

  const pageWidth = pdf.internal.pageSize.getWidth()
  const pageHeight = pdf.internal.pageSize.getHeight()
  const maxImgWidth = pageWidth - 2 * PADDING
  const maxImgHeight = pageWidth - 2 * PADDING

  const imgCanvas = document.createElement('canvas')
  imgCanvas.width = maxImgWidth
  imgCanvas.height = maxImgHeight

  const ctx = imgCanvas.getContext('2d') as CanvasRenderingContext2D
  // ctx.drawImage(imgData, 0, 0,0, 0,)
  imgCanvas.toDataURL()

  const imgProps = pdf.getImageProperties(imgData)
  imgProps.data
  
  const imgWidthOnPdf = pdf.internal.pageSize.getWidth() - PADDING * 2
  const imgHeightOnPdf = (imgProps.height * imgWidthOnPdf) / imgProps.width
  
  const nPages = Math.ceil(imgHeightOnPdf / pageHeight)

  for (let i = 0; i < nPages; i++) {
    if (i > 0) pdf.addPage()
    pdf.addImage(imgData, 'PNG', PADDING, -i * pageHeight, imgWidthOnPdf, imgHeightOnPdf)
  }
  
  pdf.save('download.pdf')
}

export async function downloadPdf2 (args: { columnNames: string[], rowGroups: RowGroup[]}) {
  const { columnNames, rowGroups } = args
  const doc = new jsPDF()

  let finalY = (doc as any).lastAutoTable.finalY || 10

  rowGroups.forEach(({ title, rows }) => {
    if (title?.length > 0) doc.text(title, 14, finalY+= 15)
    autoTable(doc, {
      margin: { top: 10 },
      startY: finalY+=5,
      head: [columnNames],
      body: rows.filter(row => !row.hidden).map(row => columnNames.map(column => row[column])),
    })
    finalY = (doc as any).lastAutoTable.finalY
  })

  addFooters(doc)

  doc.save('disc-golf-events.pdf')
}

function addFooters(doc: jsPDF) {
  const pageSize = { x: 210, y: 297 }
  const nPages = doc.getNumberOfPages()
  doc.setFontSize(10)
  doc.setFont(doc.getFont().fontName, 'italic')
  doc.setTextColor(100, 100, 100)

  for(let pageNr = 1; pageNr <= nPages; pageNr++) {
    doc.setPage(pageNr)
    // Placing this in the header now, yolo
    doc.text(new Date().toISOString().split('.')[0].split('T').join(' '), 15, 7);
    
    doc.addImage(logo, 'png', 14, pageSize.y - 10 - 4.5, 6, 6)
    doc.text(`https://polareclip.se/services/event-finder`, 22, pageSize.y - 10);
    doc.text(`${pageNr} / ${nPages}`, pageSize.x - 20, pageSize.y - 10);
  }
}