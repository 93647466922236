import { loadStripe, Stripe } from '@stripe/stripe-js'
import axios from 'axios'

interface ProductProperties { [key: string]: string }

interface CartItem {
  id: string
  title: string
  price: number
  thumbnail?: string
  properties: ProductProperties
}

export class Cart {
  private items: CartItem[] = []
  private stripePromise: Promise<Stripe | null>

  constructor (args: {
    clientStripeApiKey: string
  }) {
    const { clientStripeApiKey } = args
    this.stripePromise = loadStripe(clientStripeApiKey)
  }

  getNrOfItems () {
    return this.items.length
  }

  addItem (item: CartItem) {
    this.items.push(item)
  }

  removeItem (item: CartItem) {
    this.items = this.items.filter(x => x !== item)
  }

  getItems () {
    return this.items
  }

  getPrice () {
    return this.items.reduce((totalPrice, item) => totalPrice + item.price, 0)
  }

  async getProductPrice (product: CartItem): Promise<number> {
    const { data: price } = await axios.post('/api/get-product-price', product)
  
    return price
  }

  async checkout () {
    // const apiResult = await axios.get('http://localhost:8000/api')
    // console.log(apiResult.data)

    // const items = this.getItems()

    // const stripe = await this.stripePromise;

    // if (stripe === null) throw Error('Stripe failed to init')

    // // Call your backend to create the Checkout Session
    // const { data,  status, statusText } = await axios.post('http://localhost:8000/api/create-checkout-session', { items })

    // if (status !== 200) console.error(statusText)

    // const { sessionId } = data

    // // When the customer clicks on the button, redirect them to Checkout.
    // const result = await stripe.redirectToCheckout({
    //   sessionId,
    // })

    // if (result.error) throw Error(result.error.message)
  }
}
