export type LatLng = { lat: number, lng: number }

export function getDistanceFromLatLonInKm(lat1: number, lng1: number, lat2: number, lng2: number) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1)  // deg2rad below
  const dLon = deg2rad(lng2 - lng1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

export function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

export function getLatLngFromString(latLngString: string): LatLng {
  const latLngArray = latLngString.split(', ')
  const lat = parseFloat(latLngArray[0])
  const lng = parseFloat(latLngArray[1])
  return { lat, lng }
}

export function getDist(row: Record<string, string>, localCoords: LatLng) {
  if (row.latLng) {
    const latLngArray = row.latLng.split(', ')
    const lat = parseFloat(latLngArray[0])
    const lng = parseFloat(latLngArray[1])
    return '' + getDistanceFromLatLonInKm(lat, lng, localCoords.lat, localCoords.lng).toFixed() + ' km'
  }

  return 'Okänt avstånd'
}

export async function getCoordinates() {
  return new Promise<GeolocationPosition>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject)
  )
}
