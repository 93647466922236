import { EventAttributes, createEvents } from 'ics'
import { downloadFile } from './downloadFile'
import dateFormat from 'dateformat'
import * as types from '../types'

class InvalidEventError extends Error {}

export async function generateIcs (events: types.Event[]): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const icsEvents: EventAttributes[] = []
  
    events.forEach(event => {
      const {
        course,
        divisions,
        endDate,
        format,
        latLng,
        location,
        name,
        rounds,
        startDate,
        tier,
        tour,
        tourLink,
      } = event
      if (name === undefined) throw new InvalidEventError(`Event without a name?!`)
      if (startDate === undefined) throw new InvalidEventError(`[${name} @ ${location}], Missing startDate!`)
      if (endDate === undefined) throw new InvalidEventError(`[${name} @ ${location}], Missing endDate!`)
  
      const start = dateFormat(startDate, 'yyyy,m,d').split(',').map(x => +x) as [number, number, number]
      
      const [ey, em, ed] = dateFormat(endDate, 'yyyy,m,d').split(',')
      const _endDate = new Date(+ey, +em, +ed + 1)
      const end = [_endDate.getFullYear(), _endDate.getMonth(), _endDate.getDate()] as [number, number, number]
      
      const url = (tourLink !== undefined)
        ? tourLink.startsWith('http')
          ? tourLink
          : 'https://' + tourLink
        : undefined
      

      const geo = latLng ? {
        lat: Number.parseFloat(latLng.split(',')[0]),
        lon: Number.parseFloat(latLng.split(',')[1]),
      } : undefined
  
      const title = name
  
      const description = `
        Tour: ${tour}
        Divisions: ${divisions}
        Format: ${format}
        Rounds: ${rounds}
        Tier: ${tier}
      `
  
      icsEvents.push({
        title,
        start,
        end,
        description,
        location: `${course}, ${location}`,
        geo,
        url,
        categories: ['Disc Golf Events']
      })
    })
  
    return createEvents(icsEvents, (err, value) => {
      if (err) return reject(err)
      resolve(value)
    })
  })
}

export async function downloadIcs (events: types.Event[]) {
  const icsFile = await generateIcs(events)

  downloadFile(icsFile, 'events.ics')
}