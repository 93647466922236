export class SpeechSynthesizer {
  private voices: SpeechSynthesisVoice[] = []
  private voice!: SpeechSynthesisVoice
  
  private _muted = false
  private _volume = 1
  private _speaking = false

  constructor () {
    this.loadVoices()
  }

  get muted () { return this._muted }
  get volume () { return this._volume }
  get speaking () { return this._speaking }

  toggleMute () {
    this._muted = !this._muted
    if (this.speaking) {
      if (this._muted) {
        window.speechSynthesis.pause()
      } else {
        window.speechSynthesis.resume()
      }
    }
  }
  unMute () {
    this._muted = false
    window.speechSynthesis.resume()
  }


  async loadVoices (): Promise<SpeechSynthesisVoice[]> {
    if (this.voices.length) return this.voices

    return new Promise((resolve) =>
      window.speechSynthesis.addEventListener('voiceschanged', () => {
        this.voices = window.speechSynthesis.getVoices()
        this.voice = this.voices[0]
        resolve(this.voices)
      })
    )
  }

  setVoice (voice: SpeechSynthesisVoice) {
    this.voice = voice
  }

  setVolume (volume: number) {
    this._volume = Math.min(Math.max(volume, 0), 1)
  }

  async speak (message: string) {
    this._speaking = true
    await this.loadVoices()

    return new Promise<void>((resolve) => {
      const utterance = new SpeechSynthesisUtterance()

      utterance.addEventListener('end', () => {
        this._speaking = false
        resolve()
      })

      utterance.text = message
      utterance.voice = this.voice
      utterance.volume = this._volume
  
      window.speechSynthesis.speak(utterance)
    })
  }

  shutUp () {
    window.speechSynthesis.cancel()
  }
}